.stonkImage {
  max-width: 30px;
  max-height: 30px;
}
.hidden {
  display: none;
}

body {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    margin: 0;
}
html {
    background-color: rgb(239, 234, 230) !important;
    color: #6d6e71 !important;
}
.stonkImage {
  max-width: 30px;
  max-height: 30px;
}
.hidden {
  display: none;
}
.strikethrough {
  text-decoration: line-through;
}
.pageContainer {
    max-width: 90%;
    margin: auto;
}
ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
}
ul.header {
    background-color: rgb(255, 213, 148);
    padding: 0;
}
ul.header li a {
    color: rgb(3, 4, 48);
    font-weight: bold;
    text-decoration: none;
    padding: 15px;
    display: inline-block;
}
.content {
    background-color: #f2f6f8;
    padding: 20px;
}
.content h2 {
    padding: 0;
    margin: 0;
}
.content li {
    margin-bottom: 10px;
}
.active {
    background-color: rgb(245, 168, 86);
}

.sortable {
    cursor: pointer;
}

.sortDescend:after {
    content: "";
    margin-bottom: 2px;
    border-width: 0 5px 5px;
    display: inline-block;
    margin-left: 2px;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.sortAscend:after {
    content: "";
    border-bottom: 4px solid;
    margin-bottom: 2px;
    border-width: 0 5px 5px;
    display: inline-block;
    margin-left: 2px;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.tableClass {
  /* white-space: nowrap; */
  min-width: 100%;
  border-collapse: separate !important;
  border-radius: 8px;
  overflow: hidden;
}

.tableClass th {
  padding: 4px;
  border-bottom-width: 2px;
  text-align: left;
  background-color: white;
  cursor: pointer !important;
}

.tableClass tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.tableClass tr:nth-child(even) {
  background-color: white;
}

.tableClass td {
  padding: 5px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .pageContainer {
      margin: auto;
      max-width: 100%;
    }

    .content {
      padding: 5px;
    }

    .tableClass {
      border: 0px;
      width: 100%;
    }

    .tableClass thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
    }

    .tableClass thead tr{
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      margin-bottom: 12px;
      border-collapse: separate !important;
      display: inline-block;
      width: 100%;
      padding-right: inherit;
    }

    .tableClass tr {
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      margin-bottom: 12px;
      border-collapse: separate !important;
      display: inline-block;
      padding-right: 10px;
      width: 100%;
    }

    .tableClass td {
      display: inline-block;
      text-align: right;
      border: none;
      width: 100%;
      padding: .2rem;
    }

    .tableClass td:before {
      content: attr(data-label);
      float: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      text-align: left;
      padding-left: 10px;
      width: 25%;
    }

    .tableClass .dataSpace {
      padding-left: 45px;
    }
}
